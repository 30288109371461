<template>
  <div id="craigslist-account-add">
    <craigslist-account-form :loading="loading" @submit="createAccount" />
  </div>
</template>

<script>
import CraigslistAccountForm from './_components/CraigslistAccountForm'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CraigslistAccountAdd',
  components: {
    'craigslist-account-form': CraigslistAccountForm
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * Create a new craigslist account.
     *
     * @param {Object} data
     */
    async createAccount (data) {
      this.loading = true
      try {
        await CraigslistAPI.accounts.create(data)

        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Account <strong>${data.email}</strong> created`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'CraigslistAccounts' })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
#craigslist-account-add {
  width: 50%;
}
</style>
